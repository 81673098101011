import './App.css';
import SongList from './SongList';
import React from 'react';
import ConfirmModal from './ModalDialog';
import SearchBox from './components/SearchBox';
// import LoginButton from './components/LoginButton';
// import LogoutButton from './components/LogoutButton';
import { useAuth0 } from "@auth0/auth0-react";
import logo from "./images/branding-logo.webp";
import { Spin as Hamburger } from 'hamburger-react'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

function SongPicker() {
  // const featureFlagLogin = false;
  const [sortkey, setSortKey] = React.useState('artist');
  const [, setConfirmModalOpen] = React.useState(false);
  const [songSelected, setSongSelected] = React.useState(false);

  const [selectedArtist, setSelectedArtist] = React.useState('');
  const [selectedTitle, setSelectedTitle] = React.useState('');
  const [selectedDedication, setSelectedDedication] = React.useState('');
  const [selectedID, setSelectedID] = React.useState('');


  const [, setLoading] = React.useState(null);
  const [songData, setSongData] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [resetSearch, setResetSearch] = React.useState(false);

  // const [userName, setUserName] = React.useState('');
  const [userName, setUserName] = React.useState(() => {
    // getting stored value from lstore
    const saved = localStorage.getItem('userName');
    return saved ? saved : '';
  });

  const { isAuthenticated } = useAuth0();
  const [isOpen, setOpen] = useState(false)
  const navigate = useNavigate();

  const deleteItemByID = (songID) => {
    const filteredData = songData.filter(item => item.ID !== songID);
    setSongData(filteredData);
    return songID;
  };

  React.useEffect(() => {
    localStorage.setItem('userName', userName);
  }, [userName]);

  React.useEffect(() => {
    (async () => {
      // console.log(searchTerm)
      if (searchTerm === "") {
        return
      }

      const response = await fetch('https://svc.livejukebox.band/songs/search?q=' + searchTerm);
      const data = await response.json();
      setSongData(data);
      setLoading(false);
      setResetSearch(false);
    })();
  }, [searchTerm]);


  function sortByArtist() {
    setSortKey('artist');
  }
  function sortByTitle() {
    setSortKey('title');
  }

  React.useEffect(() => {
    (async () => {
      try {
        var filter = "";
        if (sortkey === "artist") {
          filter = "artist";
        }
        const response = await fetch("https://svc.livejukebox.band/songs/" + filter);
        const responseJson = await response.json();
        setSongData(responseJson);
        setResetSearch(false);
        setSearchTerm("");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [sortkey, resetSearch]);


  function submitSong(artist, title, dedication) {
    console.log("Enqueue - Song selected: " + selectedArtist + " - " + selectedTitle + " - " + selectedDedication);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Title: selectedTitle,
        Artist: selectedArtist,
        Message: selectedDedication,
        SubscriberRequest: isAuthenticated,
        RequesterName: userName
      })
    };
    fetch("https://svc.livejukebox.band/session/submit", requestOptions)
      .then(response => response.json())
      .then(data => {
        deleteItemByID(selectedID);
        setSongSelected(false);
        setSelectedDedication('');
        setSelectedArtist('');
        setSelectedTitle('');

      })
      .catch(error => {
        console.log('error', error);
      }
      );
  }

  React.useEffect(() => {

    if (isOpen) {
      navigate('/contact');

    }
  }, [isOpen, navigate]);

  return (
    <div className="App">
      <div className="bg-tonyblue-900 text-white flex flex-row justify-between w-screen">
        <div className="text-2xl"><h1 className='px-6'>Choose a song below:</h1></div>
        <div className=""><Hamburger rounded label="Contact us" toggled={isOpen} toggle={setOpen} /></div>
      </div>
      <div>
        <div className="w-screen grid grid-cols-1 place-items-center">
          <div className='xl:pl-10 2xl:pl-10'><img src={logo} alt="Livejukebox logo of the Live Jukebox name on a piano in the style of a neon lamp" /></div>


        </div>
      </div>
      <div className='w-screen'>
        <div className='grid grid-cols-3 w-full ml-4 pr-8'>

          <div className='py-2'>
            <button className="border border-tonyblue-900 w-full h-12 text-md md:text-xl text-white bg-tonyblue-900 font-bold" onClick={sortByArtist}>
              Sort By Artist
            </button>

          </div>
          <div className=''><p className='h-12 mt-4 text-2xl'>OR</p></div>
          <div className='py-2'>
            <button className="border border-tonyblue-900 w-full h-12 text-md md:text-xl text-white bg-tonyblue-900 font-bold" onClick={sortByTitle}>
              Sort By Title
            </button>
          </div>
          <div className='py-2'>


          </div>

        </div>
        <SearchBox
          setSearchTermFunction={setSearchTerm}
          setResetSearchFunction={setResetSearch}
        />

        <div id='song-list' className='py-2'>

          <SongList
            songData={songData}
            sortedBy={sortkey}
            setArtist={setSelectedArtist}
            setTitle={setSelectedTitle}
            setSongSelected={setSongSelected}
            setID={setSelectedID}
          />
        </div>
      </div>
      <ConfirmModal
        modalOpen={songSelected}
        closeModal={() => { setSongSelected(false); setConfirmModalOpen(false) }}
        artist={selectedArtist}
        title={selectedTitle}
        submitSong={submitSong}
        setDedication={setSelectedDedication}
        setUserName={setUserName}
        userName={userName}
      />

    </div>
  );
}

export default SongPicker;
